import React from "react";
import s from './Footer.module.css'

function Footer() {
    return (
        <div className={s.footerDiv}>
            <p>Rivieccio &copy; 2023</p>
        </div>
    )
}

export default Footer