import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import About from '../components/about/AboutComponent';
import Download from '../components/downloads/page/DownloadPage';
import Footer from '../components/footer/FooterComponent';
import Header from '../components/header/HeaderComponent';
import Login from '../components/login/LoginComponent';
import NavBar from '../components/navBar/NavBarComponent';
import s from './MainView.module.css'
import {LOCAL_KEY} from '../private/private.js'

function Main() {
    const location = useLocation()
    const navigate = useNavigate()
    const logged = window.localStorage.getItem(LOCAL_KEY!)

    useEffect(() => {
        if(!logged && location.pathname !== '/') {
            navigate('/')
            Swal.fire({
                icon: 'error',
                text: 'Debes iniciar sesión para ver el contenido'
            })
        } 
        if(logged && location.pathname === '/') navigate('/nosotros')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={s.mainDiv}>
            <div className={s.header}>
                <Header/>
                <NavBar/>
            </div>
            <div className={s.content}>
                <div className={s.contentMain}>
                    {location.pathname === '/' && <Login/>}
                    {location.pathname === '/nosotros' && <About/>}
                    {location.pathname === '/descargas' && <Download/>}
                </div>
            </div>
            <div className={s.footer}>
                <Footer/>
            </div>
        </div>
    )
}

export default Main;