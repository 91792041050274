import s from './About.module.css'

function About() {
    return (
        <div className={s.aboutDiv}>
            <p>Nogoya 1916, Banfield(1828), Buenos Aires, Argentina</p>
            <p>Tel: (+54) 11-6101-9848</p>
            <p>E-mail: <a href="mailto:herramientasrr@outlook.com">herramientasrr@outlook.com</a></p>
            <p>Horario de atención: 7:00 a 15:00 hs</p>
            <hr style={{width:"70%", backgroundColor:'red', height:'2px'}}></hr>
            <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.9731696457634!2d-58.426733384233685!3d-34.73107067175579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccde9e8d0ac49%3A0xaa0d57af4dd0473d!2sRivieccio%20SRL!5e0!3m2!1ses-419!2sar!4v1674488856492!5m2!1ses-419!2sar" 
            className={s.map} 
            loading="lazy" 
            title="rivieccio"></iframe>
        </div>
    )
}

export default About;