import React, { useState } from "react";
import s from "./Login.module.css";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { USER, LOCAL_KEY } from "../../private/private";

function Login() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    user: "",
    password: "",
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form.user || !form.password) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Debe rellenar todos los campos",
      });
    } else {
      if (form.user === USER && form.password === "ListasRR2024") {
        window.localStorage.setItem(LOCAL_KEY!, USER!);
        navigate("/nosotros");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El usuario y la contraseña no coinciden",
        });
      }
    }
  };

  const handdleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={s.loginDiv}>
      <form onSubmit={handleSubmit} className={s.loginForm} autoComplete="off">
        <input
          type="text"
          name="user"
          value={form.user}
          placeholder="Ingrese su Usuario..."
          className={s.inputForm}
          autoComplete="on"
          onChange={handdleForm}
        />
        <input
          type="password"
          name="password"
          value={form.password}
          placeholder="Ingrese su Contraseña..."
          className={s.inputForm}
          autoComplete="on"
          onChange={handdleForm}
        />
        <button type="submit" className={s.buttonForm}>
          Ingresar
        </button>
      </form>
      <div className={s.textDiv}>
        <p>
          Para ingresar a la sección de descargas debe introducir el usuario y
          contraseña. En caso de no tener, puede ponerse en contacto con
          nosotros por los siguientes medios:
        </p>
        <p>
          Tel/Wpp: (+54) 11-6101-9848 | E-mail:{" "}
          <a href="mailto:herramientasrr@outlook.com">
            herramientasrr@outlook.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default Login;
