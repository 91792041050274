export const USER = "rivieccio";
export const LOCAL_KEY = "userRR";
export const lists = [
  {
    name: "Lista RR",
    downloadName: "Lista de precios RR",
    link: "https://drive.google.com/uc?id=1h8VIkqXxno7KDZLiivgnt_qWCJoM3QIo&export=download",
  },
  {
    name: "Lista Válvulas",
    downloadName: "Lista de precios Valvulas",
    link: "https://drive.google.com/uc?id=1-AYiKnUHxQpcZ4SwWtQeFzLNQwpLyFpW&export=download",
  },
  {
    name: "Lista TG",
    downloadName: "Lista de precios TG",
    link: "https://drive.google.com/uc?id=1-9pH--t7hEXtoXa_LvJNA4JTlCyTL3EB&export=download",
  },
  {
    name: "Lista Vipal",
    downloadName: "Lista de precios Vipal",
    link: "https://drive.google.com/uc?id=1vc4_-S-TR_YLNMlRq6nqz89ELvX5XBsn&export=download",
  },
  {
    name: "Lista PX-LM",
    downloadName: "Lista de precios PX y LM",
    link: "https://drive.google.com/uc?id=10WGNhtUj16Dfz2C07F2IhYR7DFHdo97W&export=download",
  },
  {
    name: "Lista ECO",
    downloadName: "Lista de precios ECO",
    link: "https://drive.google.com/uc?id=1-R88BXBF-iq04puYkWc9IyvHQvL7f_yZ&export=download",
  },
  {
    name: "Lista Solmi",
    downloadName: "Lista de precios Solmi",
    link: "https://drive.google.com/uc?id=1-5bk52Xvi_xSaqnVooiHI8hvhENni6lo&export=download",
  },
  {
    name: "Lista RC-GP",
    downloadName: "Lista de precios RC y GP",
    link: "https://drive.google.com/uc?id=105PPI8GLOGqrJePQJggkkv0Ui17dM5Tn&export=download",
  },
  {
    name: "Lista Cubiertas",
    downloadName: "Lista de Cubiertas",
    link: "https://drive.google.com/uc?id=15H3FD-DyWvAGSLkhapyCI6mpBggCePm_&export=download",
  },
];
export const catalogues = [
  {
    name: "Catálogo RR",
    link: "https://drive.google.com/uc?id=1F14vtt2MREvRbMZEGG6oimYzgEgrQ1QQ&export=download",
  },
  {
    name: "Catálogo Válvulas",
    link: "https://drive.google.com/uc?id=1mf9JaQV5QiEdC-skAUAKBTyWiuy-bM4Y&export=download",
  },
];
