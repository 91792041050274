import React from "react";
import s from "./Header.module.css"
import logo from "../../img/Logo RR Render.png"

function Header() {
    return (
        <div className={s.divTitle}>
            <img src={logo} className={s.logo} alt="Logo Rivieccio" />
            <h1 className={s.title}>RIVIECCIO<span className={s.acronym}>SRL</span></h1>
        </div>
    )
}

export default Header;
