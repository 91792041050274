import excel from '../../../img/excell.png'
import s from './Lists.module.css'
import {lists} from '../../../private/private.js'
import { downloaderFunct} from '../../../utils'

function Lists() {

    return (
        <>
            {lists.map((el) => {
                return (
                    <div className={s.individualList} key={el.name}>
                        <button id={el.name} onClick={() => {downloaderFunct(el.link , el.name)}} className={s.buttonList}>
                            <img src={excel} alt="excel logo" className={s.listImg}/>
                            <p style={{marginRight:'10px'}}>|</p>
                            <p>{el.name}</p>
                        </button>
                    </div>
                )
                })
            }
        </>
    )
}

export default Lists;