import s from './Catalogue.module.css'
import pdf from '../../../img/pdfpng.png'
import { catalogues } from '../../../private/private';
import { downloaderFunct } from '../../../utils';

function Catalogue() {
    return (
        <>
            {catalogues.map((el) => {
                    return (
                        <div className={s.individualCatalogue} key={el.name}>
                            <button onClick={() => {downloaderFunct(el.link, el.name)}} className={s.buttonCatalogue}>
                                <img src={pdf} alt="pdf logo" className={s.catalogueImg}/>
                                <p style={{marginRight:'10px'}}>|</p>
                                <p>{el.name}</p>
                            </button>
                        </div>
                    )
                })
            }
           
        </>
    )
}

export default Catalogue;