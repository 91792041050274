import {useState} from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import s from './NavBar.module.css'

function NavBar() {
    const location = useLocation()
    const [path, setPath] = useState('nosotros')

    return (
        <div className={s.navBar}>
            {(location.pathname === '/nosotros' || location.pathname === '/descargas') &&
            <>
                <Link to={'/nosotros'} className={path === 'nosotros' ? s.titleLink1Active : s.titleLink1} onClick={() => setPath('nosotros')}>Nosotros</Link>
                <p className={s.title}>|</p>
                <Link to={'/descargas'} className={path === 'descargas' ? s.titleLink2Active : s.titleLink2} onClick={() => setPath('descargas')}>Descargas</Link>
            </>}
            {location.pathname === '/' && 
            <p className={s.title}>Acceso</p>}
        </div>
    )
}

export default NavBar;