import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from '../../pages/MainView.page';
import s from './App.module.css'

function App() {
  useEffect(() => {
    return localStorage.removeItem('userRR')
  }, [])

  return (
    <div className={s.divContainer}>
      <Routes>
        <Route path='*' element={<Main/>}/>
      </Routes>
    </div>
  );
}

export default App;
