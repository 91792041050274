import s from './Download.module.css'
import Lists from '../lists/ListsComponent';
import Catalogue from '../catalogue/Catalogue.component';
import {lists} from '../../../private/private.js'
import download from '../../../img/download-logo.png'
import { downloaderFunct } from '../../../utils';

function Download() {

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        
        lists.forEach( el => {
            downloaderFunct(el.link, el.downloadName, 'multiple')
        })
    }

    return (
        <div className={s.downloadDiv}>
            <div className={s.listsDiv}>
                <div className={s.titleDiv}>
                    <h1 className={s.listTitle}>Listas</h1>
                    <button 
                        onClick={handleClick} 
                        title='Debe habilitar las pop-ups (ventanas emergentes) para poder descargar todo' 
                        className={s.buttonDownload}><img src={download} className={s.downloadImg} alt="" />Descargá todo
                    </button>
                </div>
                <div className={s.sectionList}>
                   <Lists/>
                </div>
            </div>
            <div className={s.catalogueDiv}>
                <h1 className={s.catalogueTitle}>Catálogos</h1>
                <div className={s.sectionCatalogue}>
                    <Catalogue/>
                </div>
            </div>
        </div>
    )
}

export default Download;